<template>
  <!-- Table Container Card -->
  <b-card no-body>

    <b-card-body class="text-center">
      <b-card-title>{{ periodReportText }}</b-card-title>
    </b-card-body>


        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center"
          v-if="isLoading">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>


    <b-table-simple id="profit-loss-table" sticky-header="55vh">
      <b-thead>
        <b-tr>
          <b-th v-for="column in tableColumns" :key="column.key" :class="column.thClass" >{{ column.label }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(prop, index) in itemLists" :key="prop.coa">
          <b-td
            v-for="(column, idx) in tableColumns"
            :key="`${column.key}-${index}`"
            :class="stylingCOA(prop.rowNumber, column.tdClass, idx)">
            <div v-if="column.key === 'branch'" >
              <span class="font-weight-bolder d-block text-nowrap">
                  {{ prop[column.key] }}
              </span>
            </div>
            <div v-else>
              <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (prop[column.key] > 0) ? 'text-success' : 'text-danger' ]">
                <div v-if="prop[column.key] >= 0 ">
                  {{ formatCurrency(prop[column.key],false) }}
                </div>
                 <div v-else>
                 ( {{ formatCurrency(prop[column.key] * -1, false) }} )
                  </div>
              </span>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div class="pt-2 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-end">
      <div class="mb-0 h4 font-weight-bolder">Total Rp. {{ itemLists[itemLists.length - 1] ? itemLists[itemLists.length - 1].balance_view : '0,00' }}</div>
    </div>
    <div class="pt-2 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-end">
      <b-alert
        variant="danger"
        :show="isAlert"
      >
        <div class="alert-body">
          {{ alertText }}
        </div>
      </b-alert>
    </div>

    <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between">
      <b-button
        class="mb-sm-1 mb-md-0"
        variant="outline-primary"
        :to="{ name: 'apps-transactions-ch-generate-list' }"
      >
        <feather-icon
          icon="ArrowLeftIcon"
        />
        {{ $t('globalActions.backToList') }}
      </b-button>

      <b-button
        v-if="!form.period.is_closed"
        variant="primary"
        type="submit"
        @click="handleSubmit"
        :disabled="isAlert"
      >
        <feather-icon
          icon="SaveIcon"
        />
        {{ $t('apps.transactions.chbacc.chGenerate.singular.generate') }}
      </b-button>

      <!-- <b-button
        v-else
        variant="primary"
        type="submit"
        @click="handleSubmit"
        :disabled="isAlert"
      >
        <feather-icon
          icon="SaveIcon"
        />
        {{ $t('apps.transactionsClosing.actions.reopen') }}
      </b-button> -->
    </div>

  </b-card>

</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  // BTable,
  // BSpinner,
  BButton,
  BAlert,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BSpinner
} from 'bootstrap-vue'

import { startOfDay, startOfMonth, endOfMonth, intervalToDuration, format, parse } from 'date-fns'
import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import router from '@/router'
import store from '@/store/index'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// to access i18n
// import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BAlert,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BSpinner
  },
  setup () {
    const form = ref({
      id: router.currentRoute.params.id,
      period: {},
      dateStart: '',
      dateEnd: ''
    })

    const company = ref(store.state.user.currentCompany)
    const periodReportText = ref()
    const isAlert = ref()
    const isLoading = ref(true)
    const alertText = ref()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { toast, $put, $get } = useHttp()

    // const { $t } = useLocalization()

    // Table Handlers
    const tableColumns = [
      { key: 'branch', label: 'office' },
      { key: 'ch_ar', label: 'AR', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_ap', label: 'AP', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_total', label: 'Tot CH', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_brand_kg', label: 'Berat', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_brand', label: 'Fee Brand', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_ict_cn', label: 'Tot Resi', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_ict', label: 'Fee ICT', thClass:'text-right', tdClass: 'text-right'  },
      { key: 'ch_grand_total', label: 'Grand Total', thClass:'text-right', tdClass: 'text-right' }
    ]


    const getPeriod = async () => {
      const period = await $get({url: `transaction/chbacc/ch-generate/${form.value.id}`})
      form.value.period = period.data
      const formatString = 'dd/MM/yyyy'
      const thisPeriod = new Date(form.value.period.year, form.value.period.month, 0)
      form.value.dateStart = format(startOfMonth(thisPeriod), formatString)
      form.value.dateEnd = format(endOfMonth(thisPeriod), formatString)
    }

    const getReport = async () => {
      const params = [
        `dateYear=${form.value.period.year}`,
        `dateMonth=${form.value.period.month}`
      ]

      const url = `transaction/chbacc/ch-generate/ch-detail?${ params.join('&') }`
      await fetchLists(url)

      isLoading.value = false
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      periodReportText.value = `${formatStartDate} to ${formatEndDate}`
    }

    const setPadding = (row) => {
      return `padding-left: ${(row.account_level - 1) * 30}px;`
    }

    const stylingCOA = (rowNumber, tdClass, idx) => {
      console.log(rowNumber)
      return {
        [tdClass]: true,
        'text-right pr-0': idx === 0 && rowNumber === 4,
        'header-category-account': rowNumber === 0,
        'child-account': rowNumber === 2,
        'total-category-account': rowNumber === 1 || rowNumber === 3,
        'total-top-category-account': rowNumber === 4
      }
    }

    const firstCompanyPeriod = ref()
    // const getFirstPeriod = async () => {
    //   const periods = await $get({url: 'transaction/chbacc/ch-generate'})
    //   isLoading.value = false
    //   const firstPeriod = periods.data[0]
    //   firstCompanyPeriod.value = new Date(firstPeriod.year, firstPeriod.month, 0)
    // }

    const checkAlert = () => {
      const thisPeriod = new Date(form.value.period.year, form.value.period.month, 0)
      const lastCompanyPeriod = new Date(company.value.closing_year, company.value.closing_month, 0)

      const periodInterval = intervalToDuration({
        start: thisPeriod,
        end: lastCompanyPeriod
      })

      if (!company.value.closing_year) {
        isAlert.value = thisPeriod.toString() !== firstCompanyPeriod.value.toString()
        alertText.value = 'Please close the previous month first'
      } else {
        if (!form.value.period.is_closed) {
          isAlert.value = periodInterval.years > 0
          alertText.value = 'Please close the previous month first'
        } else {
          isAlert.value = thisPeriod < lastCompanyPeriod
          alertText.value = 'Please open next month first'
        }
      }
    }

    onMounted(async () => {
      await getPeriod()
      await getReport()
      // await getFirstPeriod()
      updatePeriodText()
      checkAlert()
    })

    return {
      isLoading,
      setPadding,
      stylingCOA,
      form,
      toast,
      $put,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      formatCurrency,
      tableColumns,
      periodReportText,
      updatePeriodText,
      isAlert,
      alertText,
      company
    }
  },
  methods: {
    handleSubmit () {
      const form = {
        id: this.form.id,
        is_closed: !this.form.period.is_closed,
        closing_date: format(startOfDay(new Date()), 'yyyy-MM-dd'),
        dateStart: this.form.dateStart,
        dateEnd: this.form.dateEnd
      }
      this.isLoading = true

      const $swal = this.$swal
      $swal({
        title: `${ !this.form.period.is_closed ? this.$t('confirm.closeThis') : this.$t('confirm.openThis') } ${this.$t('apps.transactionsClosing.singular.period')} ?`,
        text: `${ !this.form.period.is_closed ? this.$t('apps.transactionsClosing.alert.afterClosed') : this.$t('apps.transactionsClosing.alert.afterOpened') }`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async ({value: password}) => {
        if (password) {
          const callbackSuccess = (res) => {
            console.log(res)
            // store.commit('user/SET_CURRENT_COMPANY', res.data.company)
            router.replace({ name: 'apps-transactions-ch-generate-list' })
            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: `${this.$t('feedback.success')} ${ !this.form.period.is_closed ? this.$t('confirm.closeThis') : this.$t('confirm.openThis') } ${this.$t('apps.transactionsClosing.singular.period')}.`
              }
            })
          }

          this.$put({
            data: form,
            url: `transaction/chbacc/ch-generate_new/${this.form.id}`
          }).then((res) => callbackSuccess(res))

          // this.$post({
          //   data:forms,
          //   url: `/transaction/purchase/invoice`
          // }).then((res) => callbackSuccess(res))
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
